import React, { useEffect, useState, useRef, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import PopupEditQrcodeNew from '../DesignQrcode/PopupEditQrcodeNew';
import { PopupDelete } from '../DesignQrcode/PopupDelete';
import { generateQRWithLinkImage } from './../DesignQrcode/ModelQRcode';
import PopupSetUrl from './PopupSetUrl';
const PopupShowQrcode = (props) => {
  const canvasRef = useRef();

  const [popupEdit, setPopupEdit] = useState(false);
  const [popupEditData, setPopupEditData] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);

  useEffect(() => {
    buildQrcode();
  });

  const getDesigne = useCallback(async (shape) => {
    let token = localStorage.getItem('sctu');
    const apiDesigne = process.env.REACT_APP_API + '/api/design/onedesigne';
    try {
      const response = await fetch(apiDesigne, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shape: shape,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        return json.design;
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const buildQrcode = async () => {
    let myPath =
      process.env.REACT_APP_URL2QR + '/api/url?getlink=' + props.info.getlink;
    let MyModelQRcode = await getDesigne(props.info.shape);
    await generateQRWithLinkImage(
      myPath,
      canvasRef.current,
      '',
      {
        color_dark: props.info.color_dark,
        color_light: props.info.color_light,
      },
      null,
      MyModelQRcode,
      process.env.REACT_APP_DASHBOARD + '/static/qr/'
    );
  };
  const handleEditQrcode = (event) => {
    event.preventDefault();
    const newPopupEdit = !popupEdit;
    setPopupEdit(newPopupEdit);
  };

  const handleEditData = (event) => {
    event.preventDefault();
    const newPopupEditData = !popupEditData;
    setPopupEditData(newPopupEditData);
  };
  const handleDelete = (event) => {
    event.preventDefault();
    const newPopupDelete = !popupDelete;
    setPopupDelete(newPopupDelete);
  };

  const Download = async (event) => {
    event.preventDefault();
    let MyModelQRcode = await getDesigne(props.info.shape);
    let path =
      process.env.REACT_APP_URL2QR + '/api/url?getlink=' + props.info.getlink;
    let URLQR = await generateQRWithLinkImage(
      path,
      canvasRef.current,
      '',
      {
        color_dark: props.info.color_dark,
        color_light: props.info.color_light,
      },
      null,
      MyModelQRcode,
      process.env.REACT_APP_DASHBOARD + '/static/qr/'
    );
    let link = document.createElement('a');
    link.href = URLQR;
    link.download = 'Qrcode.png';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div className="popup">
      <div className="popup_inner_canvas">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        {/*  */}
        <div className="row justify-content-center">
          <button
            className="btn btn-warning  btn-circle"
            onClick={(e) => handleEditQrcode(e)}
          >
            <BuildIcon />
          </button>
          <button
            className="btn btn-secondary btn-circle"
            onClick={(e) => Download(e)}
          >
            <GetAppIcon />
          </button>

          <button
            className="btn  btn-dark btn-circle"
            onClick={(e) => handleEditData(e)}
          >
            <CreateIcon />
          </button>
        </div>
        {/*  */}
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div id="container">
              <canvas ref={(el) => (canvasRef.current = el)}></canvas>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <button
            className="btn btn-danger btn-circle"
            onClick={(e) => handleDelete(e)}
          >
            <DeleteIcon />
          </button>
        </div>
        {popupEdit ? (
          <PopupEditQrcodeNew
            closePopup={(e) => handleEditQrcode(e)}
            text={props.info.id}
            myid={props.info.id}
            dark={props.info.color_dark}
            light={props.info.color_light}
            shape={props.info.shape}
            urlqrcode={
              process.env.REACT_APP_URL2QR +
              '/api/url?getlink=' +
              props.info.getlink
            }
            namecontainer={'spe'}
            type={'url'}
            redirection={'/qrcode-url'}
          />
        ) : (
          <div></div>
        )}
        {popupDelete ? (
          <PopupDelete
            text="Confirm you want to delete this Card"
            idcard={props.info.id}
            type={'url'}
            redirection={'/qrcode-url'}
            closePopup={(e) => handleDelete(e)}
          />
        ) : (
          <div></div>
        )}
        {popupEditData ? (
          <PopupSetUrl
            myid={props.info.id}
            url={props.info.url}
            title={props.info.title}
            closePopup={(e) => handleEditData(e)}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default PopupShowQrcode;
