import React, { useState, useEffect } from 'react';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
import { PromoCode } from './PromoCode';
// import Checkout from './old-stripe/Checkout';
import Subscription from './subscription';

export const ListDetailPrice = [
  {
    name: 'Standard',
    price: '10€',
    duration: 'month',
    attributes: [
      '2 Qrcode dynamique pour Virtual Card (vcard)',
      '12 Qrcode dynamique pour Link URL',
      'Static QRcodes, Unlimited',
    ],
    idPrice: 'SQ31',
    power_nb: 1,
  },
  {
    name: 'Premium',
    price: '79,90€',
    duration: 'year',
    attributes: [
      '5 Qrcode dynamique pour Virtual Card (vcard)',
      '50 Qrcode dynamique pour Link URL',
      'Static QRcodes, Unlimited',
    ],
    idPrice: 'SQ365',
    power_nb: 2,
  },
  // {
  //   name: 'Ultimate',
  //   price: '300€',
  //   duration: 'year',
  //   attributes: [
  //     '50 Qrcode dynamique pour Virtual Card (vcard)',
  //     '300 Qrcode dynamique pour Link URL',
  //     'Static QRcodes, Unlimited',
  //   ],
  //   idPrice: 'SQCOMPANY',
  //   power_nb: 3,
  // },
];

function Pricing(props) {
  const list = props.mylist;
  const last_ref = props.reference_id;
  const day_end_contract = props.day_end_contract;
  const power_nb_contact = props.power_nb;
  // console.log(power_nb_contact);
  const listItems = list.map((info, i) => (
    <div className="col-lg-4 mt-2" key={i}>
      <div className="card bg-success mb-lg-0 rounded-lg shadow">
        <div className="card-header">
          <h2 className="card-title text-white-50 text-uppercase text-center">
            {info.name}
          </h2>
          <h3 className="h1 text-white text-center">
            {info.price + '/' + info.duration}
            <span className="h6 text-white-50"></span>
          </h3>
        </div>

        <div className="card-body bg-light rounded-bottom">
          {(info.power_nb > power_nb_contact) & (info.idPrice !== last_ref) ||
          day_end_contract < 6 ? (
            <div>
              <div className="paypal-button">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  value={info.idPrice}
                  onClick={(e) => props.handleClick(e)}
                >
                  Buy Now
                </button>
              </div>
            </div>
          ) : (
            <div>
              Your Contact still update !!
              <br />
              You can update it in {day_end_contract - 5} days
            </div>
          )}

          <ul className="list-unstyled mb-4">
            {info.attributes.map((item, i) => (
              <li className="mb-3" key={i}>
                <span className="mr-3">
                  <i className="fa fa-check text-success"></i>
                </span>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));
  return listItems;
}
function formaDate(date) {
  const event = new Date(date);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return event.toLocaleDateString(undefined, options);
}
async function dateDiff(date1, date2) {
  let diff = {}; // Initialisation du retour
  let tmp = date2 - date1;

  tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
  diff.sec = tmp % 60; // Extraction du nombre de secondes

  tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
  diff.min = tmp % 60; // Extraction du nombre de minutes

  tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
  diff.hour = tmp % 24; // Extraction du nombre d'heures

  tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
  diff.day = tmp;

  return diff;
}
export const Payement = () => {
  const [data, setData] = useState([]);
  const [diffDate, setDiffDate] = useState();
  const [subscription, setSubscription] = useState({
    end_contract: '',
    lastpayment: '',
    nameforfait: '',
    numbercvf: '',
    numberurl: '',
    reference_id: '',
    power_nb: '',
  });
  const token = localStorage.getItem('sctu');
  const handleClick = async (event) => {
    event.preventDefault();
    await handleSubmit(event.target.value);
  };

  const handleSubmit = async (val) => {
    const apiSetQrcode = process.env.REACT_APP_API + '/api/paypal/payment';
    // console.log(val);
    let reference_id = val;
    try {
      //console.log(token);
      const response = await fetch(apiSetQrcode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify({
          reference_id,
        }),
      });
      //  await //console.log(response);
      if (response.ok) {
        const json = await response.json();
        window.location.assign(json.link);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const products = await fetch(
        process.env.REACT_APP_API + '/api/users/getsubscription',
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      let myLastPayment = null;
      if (products.info.lastpayment !== null) {
        myLastPayment = formaDate(products.info.lastpayment);
      }
      setSubscription({
        end_contract: formaDate(products.info.end_contract),
        lastpayment: myLastPayment,
        nameforfait: products.info.nameforfait,
        numbercvf: products.info.numbercvf,
        numberurl: products.info.numberurl,
        reference_id: products.info.reference_id,
        power_nb: products.info.power_nb,
      });
      let myDiffDate = await dateDiff(
        new Date(),
        new Date(products.info.end_contract)
      );
      setDiffDate(myDiffDate.day);
      // await console.log(myDiffDate.day);
    };
    fetchData();
    setData(ListDetailPrice);
  }, [token]);
  return (
    <div>
      <MenuProfileVertical />
      <MenuProfileVerticalPhone />
      <Subscription data={subscription} day_end_contract={diffDate} />
      <div className="row justify-content-center">
        <div className="col-md-12  ">
          <h1 className="text-center mb-5">Payement</h1>
        </div>
        <div className="container ">
          <div className="row justify-content-center ">
            {data.length !== 0 ? (
              <Pricing
                mylist={data}
                handleClick={handleClick}
                reference_id={subscription.reference_id}
                day_end_contract={diffDate}
                power_nb={subscription.power_nb}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <PromoCode handleClick={handleClick} />
      </div>
    </div>
  );
};
