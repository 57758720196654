import React from 'react';
// import { process.env.REACT_APP_API } from './../../../CONST/constant';
import './subscription.css';

const Subscription = (props) => {
  let data = props.data;
  let decompte = props.day_end_contract;
  //console.log(props.data);
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1 className="text-center mtop-title mb-5">Abonnement</h1>
          {decompte < 0 ? (
            <div>
              <h1 style={{ color: '#e57373' }}> ACCOUNT NOT ACTIVATED</h1>
              <h2>You Need to Subscribe </h2>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="col-md-6 ">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-5">
                  <h2 className="mb-0 titlesub">Name Subscription</h2>
                </div>
                <div className="col-sm-7 text-secondary">
                  {data.nameforfait}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-5">
                  <h2 className="mb-0 titlesub">Last Payment</h2>
                </div>
                {data.lastpayment != null ? (
                  <div className="col-sm-7 text-secondary">
                    {data.lastpayment}
                  </div>
                ) : (
                  <div className="col-sm-7 text-secondary">No Payment</div>
                )}
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-5">
                  <h2 className="mb-0 titlesub">End Subscription</h2>
                </div>
                <div className="col-sm-7 text-secondary">
                  {data.end_contract}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-5">
                  <h2 className="mb-0 titlesub">Number card available</h2>
                </div>
                <div className="col-sm-7 text-secondary">{data.numbercvf}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-5">
                  <h2 className="mb-0 titlesub">Number URL available</h2>
                </div>
                <div className="col-sm-7 text-secondary">{data.numberurl}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
