import React, { useEffect, useRef, useState, useCallback } from 'react';
// import { process.env.REACT_APP_API, process.env.REACT_APP_URL2QR } from '../../../CONST/constant';
import BuildIcon from '@material-ui/icons/Build';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import { PopupInfo } from './../DesignQrcode/PopupInfo';
import { PopupDelete } from './../DesignQrcode/PopupDelete';
import { generateQRWithLinkImage } from '../DesignQrcode/ModelQRcode.js';
import PopupEditQrcode from '../DesignQrcode/PopupEditQrcode';

import ReactPaginate from 'react-paginate';

const CardQRcode = (props) => {
  // const token = localStorage.getItem('sctu');
  const canvasRef = useRef([]);
  // const [offset, setOffset] = useState(0);
  const [perPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [orgtableData, setOrgtableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  let toggleDelete = [];
  let toggleQrcode = [];
  let toggleInfo = [];
  props.mylist.forEach((el) => {
    toggleDelete.push(false);
    toggleQrcode.push(false);
    toggleInfo.push(false);
  });

  const [Delete, setDelete] = useState(toggleDelete);
  const [EditQrcode, setEditQrcode] = useState(toggleQrcode);
  const [Info, setInfo] = useState(toggleInfo);

  // const getLogo = useCallback(
  //   async (qrcode) => {
  //     const myname = qrcode.logo;
  //     const url2 = process.env.REACT_APP_API + '/api/upload/logo/' + myname;
  //     try {
  //       const response2 = await fetch(url2, {
  //         headers: { Authorization: 'bearer ' + token },
  //       });
  //       if (response2.ok) {
  //         const json2 = await response2;
  //         return json2.url;
  //       } else {
  //         //console.log('not logo ');
  //         return null;
  //       }
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   },
  //   [token]
  // );
  const handlePageClick = async (e) => {
    const selected = e.selected;
    setSelectedPage(selected);
    await showQRcode(selected);
  };
  const getDesigne = useCallback(async (shape) => {
    let token = localStorage.getItem('sctu');
    const apiDesigne = process.env.REACT_APP_API + '/api/design/onedesigne';
    try {
      const response = await fetch(apiDesigne, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shape: shape,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        return json.design;
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const showQRcode = useCallback(
    async (selected) => {
      const data = orgtableData;
      //  console.log(data);
      const offset1 = selected * perPage;
      const slice2 = data.slice(offset1, offset1 + perPage);
      let countPage = Math.ceil(data.length / perPage);
      setCurrentPage(selected);
      // setOffset(offset1);
      setPageCount(countPage);
      setTableData(slice2);
      canvasRef.current = canvasRef.current.slice(0, slice2.length);
      canvasRef.current.forEach((el, index) => {
        let qrcode = slice2[index];
        const DataFetch1 = async (el, index, qrcode) => {
          let logo = null; //await getLogo(qrcode);
          let path = process.env.REACT_APP_URL2QR + '/vcard/' + qrcode.url_cvf;
          let MyModelQRcode = await getDesigne(qrcode.shape);
          await generateQRWithLinkImage(
            path,
            el,
            index,
            qrcode,
            logo,
            MyModelQRcode,
            process.env.REACT_APP_DASHBOARD + '/static/qr/'
          );
        };
        DataFetch1(el, index, qrcode);
      });
    },
    [orgtableData, perPage, getDesigne]
  );

  useEffect(() => {
    let slice = props.mylist.slice(0, perPage);
    let countPage = Math.ceil(props.mylist.length / perPage);
    setOrgtableData(props.mylist);
    // function data(slice, countPage) {
    setCurrentPage(1);
    setTableData(slice);
    setPageCount(countPage);
    canvasRef.current = canvasRef.current.slice(0, slice.length);
    setTimeout(() => {
      //setCount('Timeout called!');
      showQRcode(selectedPage);
    }, 1000);
    //  return () => clearTimeout(timer);
  }, [props, selectedPage, perPage, showQRcode]);

  const handleEditQrcode = (event, i) => {
    event.preventDefault();
    const newEditQrcode = [...EditQrcode];
    newEditQrcode[i] = !newEditQrcode[i];
    setEditQrcode(newEditQrcode);
  };

  const handleDelete = (event, i) => {
    event.preventDefault();
    const newDelete = [...Delete];
    newDelete[i] = !newDelete[i];
    setDelete(newDelete);
  };
  const handleInfo = (event, i) => {
    event.preventDefault();
    const newInfo = [...Info];
    newInfo[i] = !newInfo[i];
    setInfo(newInfo);
  };

  const Download = async (event, i) => {
    event.preventDefault();
    let qrcode = props.mylist[currentPage];
    console.log(qrcode);
    let MyModelQRcode = await getDesigne(qrcode.shape);
    let path = process.env.REACT_APP_URL2QR + '/vcard/' + qrcode.url_cvf;
    let logo = null; //await getLogo(qrcode);
    let URLQR = await generateQRWithLinkImage(
      path,
      canvasRef.current[i],
      i,
      qrcode,
      logo,
      MyModelQRcode,
      process.env.REACT_APP_DASHBOARD + '/static/qr/'
    );
    let link = document.createElement('a');
    link.href = URLQR;
    link.download = 'Qrcode.png';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const listItems = tableData.map((info, i) => (
    <div className="col-md-10" key={i}>
      <div className="row justify-content-center">
        <h2>
          Card :{' '}
          {info.firstname +
            ` ` +
            info.lastname +
            ` ` +
            info.organization +
            ` ` +
            info.title}
        </h2>
      </div>
      <div className="row justify-content-center">
        <button
          className="btn btn-warning  btn-circle"
          onClick={(e) => handleEditQrcode(e, i)}
        >
          <BuildIcon />
        </button>
        <button
          className="btn btn-secondary btn-circle"
          onClick={(e) => Download(e, i)}
        >
          <GetAppIcon />
        </button>
        <button
          className="btn btn-danger btn-circle"
          onClick={(e) => handleDelete(e, i)}
        >
          <DeleteIcon />
        </button>
        <button
          className="btn  btn-info btn-circle"
          onClick={(e) => handleInfo(e, i)}
        >
          <PermDeviceInformationIcon />
        </button>
        <a href={`/setcard?idvcard=` + info.id}>
          <button className="btn  btn-dark btn-circle">
            <EditIcon />
          </button>
        </a>
      </div>
      <div id={'container' + i} className="row justify-content-center ">
        <canvas ref={(el) => (canvasRef.current[i] = el)}></canvas>
      </div>

      <div className="row justify-content-center">
        {EditQrcode[i] ? (
          <PopupEditQrcode
            closePopup={(e) => handleEditQrcode(e, i)}
            text={info.id}
            myid={info.id}
            // dark={info.color_dark}
            // light={info.color_light}
            // shape={info.shape}
            urlqrcode={process.env.REACT_APP_URL2QR + '/vcard/' + info.url_cvf}
            type={'vcard'}
            redirection={'/qrcode-card'}
          />
        ) : (
          <div></div>
        )}
      </div>
      <div className="row justify-content-center">
        {Delete[i] ? (
          <PopupDelete
            text="Confirm you want to delete this Card"
            idcard={info.id}
            closePopup={(e) => handleDelete(e, i)}
            redirection={'/qrcode-card'}
          />
        ) : (
          <div></div>
        )}
      </div>
      <div className="row justify-content-center">
        {Info[i] ? (
          <PopupInfo card={info} closePopup={(e) => handleInfo(e, i)} />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  ));
  return (
    <div>
      <div className="row justify-content-center pl-5-model">{listItems}</div>
      <div className="row justify-content-center pl-5-model">
        <ReactPaginate
          previousLabel={'prev'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          initialPage={currentPage}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={(e) => handlePageClick(e)}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
};

export default CardQRcode;
