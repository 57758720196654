import React, { useRef, useEffect, useState, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
// import ImageIcon from '@material-ui/icons/Image';
// import SvgIcon from '@material-ui/core/SvgIcon';
import './../DesignQrcode/MyCanvas.css';
import ListButtonLogo from './ListButtonLogo';
import { generateQRWithLinkImage } from './ModelQRcode.js';

const PopupEditQrcode = (props) => {
  const canvasRef = useRef();
  // const [Logo, setLogo] = useState(null);
  // const [file, setFile] = useState(null);
  // const [designe, setDesigne] = useState([]);
  const [dark, setDark] = useState('#000000');
  const [light, setLight] = useState('#FFFFFF');
  const [theShape, setmyShape] = useState('circle');

  const getDesigne = useCallback(async (shape) => {
    let token = localStorage.getItem('sctu');
    const apiDesigne = process.env.REACT_APP_API + '/api/design/onedesigne';
    try {
      const response = await fetch(apiDesigne, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shape: shape,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        return json.design;
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  // const getDesigne2 = async (shape) => {
  //   setAllDesigne(jsonData[shape]);
  //   return jsonData[shape];
  // };
  const buildQrcode = useCallback(
    async (shape) => {
      let myPath = props.urlqrcode;
      let myShape = await getDesigne(shape);
      console.log(myShape);
      generateQRWithLinkImage(
        myPath,
        canvasRef.current,
        '',
        { color_dark: dark, color_light: light },
        null,
        myShape,
        process.env.REACT_APP_DASHBOARD + '/static/qr/'
      );
    },
    [dark, light, props, getDesigne]
  );

  useEffect(() => {
    buildQrcode(theShape);
  }, [buildQrcode, theShape]);

  const handleDark = async (event) => {
    event.preventDefault();
    await setDark(event.currentTarget.value);
    await buildQrcode(theShape);
  };
  const handleLight = async (event) => {
    event.preventDefault();
    await setLight(event.currentTarget.value);
    await buildQrcode(theShape);
  };
  const handlemyShape = async (event) => {
    let mymyShape = event.currentTarget.id;
    await setmyShape(mymyShape);
    await buildQrcode(mymyShape);
    console.log(mymyShape);
  };
  // const handleFile = async (event) => {
  //   let info = event.target.files[0];
  //   if (info !== null) {
  //     try {
  //       let MyUrl = await URL.createObjectURL(info);
  //       await setLogo(MyUrl);
  //       await setFile(info);
  //       await generateQR();
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiSetQrcode = process.env.REACT_APP_API + '/api/qrcode/setqrcode';
    let token = localStorage.getItem('sctu');
    let id_type = props.myid;
    let color_dark = dark;
    let color_light = light;
    let shape = theShape;
    //let jsonlogo = await this.pic_logo();
    let logo = null; //Logo; // jsonlogo;
    let type = props.type;
    try {
      const response = await fetch(apiSetQrcode, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_type,
          shape,
          color_light,
          color_dark,
          logo,
          type,
        }),
      });
      if (response.ok) {
        window.location.assign(props.redirection);
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (err) {
      console.error(err);
      //console.log('JWT');
      window.location.assign('/signin');
    }
  };

  return (
    <div className="popup">
      <div className="popup_inner_canvas">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>

        <div className="row justify-content-center">
          <h1 className="data-url-title">{props.text}</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-2">
            <div className="front-back-color">
              <div>
                <label>
                  Front:
                  <input
                    type="color"
                    onChange={(e) => handleDark(e)}
                    value={dark}
                  />
                  {/* <span>{dark}</span> */}
                </label>
              </div>
              <div>
                <label>
                  Back:
                  <input
                    type="color"
                    onChange={(e) => handleLight(e)}
                    value={light}
                  />
                  {/* <span>{light}</span> */}
                </label>
              </div>
            </div>
            {/* <div>
                <div>Add Logo:</div>
                <label
                  id="add-image-label-file"
                  onChange={(e) => handleFile(e)}
                >
                  <span className="addimage">
                    <ImageIcon style={{ fontSize: 40 }} />
                  </span>
                  <input
                    id="add-image-button"
                    type="file"
                    name="filetoupload"
                    accept="image/png, image/jpeg"
                  />
                </label>
              </div> */}
          </div>
          <ListButtonLogo handlemyShape={handlemyShape} />
          <div className="col-md-2">
            <div className="row justify-content-center">
              <div className="button-save-canvas">
                <button
                  className="btn  btn-primary btn-lg"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div id="container">
              <canvas ref={(el) => (canvasRef.current = el)}></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopupEditQrcode;
