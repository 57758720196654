import React, { useState } from 'react';

export const PromoCode = (props) => {
  const [promo, setPromo] = useState('');
  const handlePromo = (e) => {
    setPromo(e.target.value);
  };
  return (
    <div className="col-md-4 " style={{ margin: '10px' }}>
      <div className="input-group">
        <div className="input-group-prepend">
          <button
            className="btn btn-outline-secondary"
            type="button"
            value={promo}
            onClick={(e) => props.handleClick(e)}
          >
            Valide Code Promo
          </button>
        </div>
        <input
          type="text"
          className="form-control"
          onChange={(e) => handlePromo(e)}
          value={promo}
          id="promo"
          name="promo"
          placeholder="Promo Code"
        />
      </div>
    </div>
  );
};
