import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import iconTooth from './../../../img/tooth.png';
import iconFire from './../../../img/fire.png';
import iconLunette from './../../../img/lunette-icon.png';
const listIcons = [
  {
    id: 0,
    name: 'square',
    balise: <CheckBoxOutlineBlankIcon id="square" style={{ fontSize: 40 }} />,
  },
  {
    id: 1,
    name: 'circle',
    balise: <RadioButtonUncheckedIcon id="circle" style={{ fontSize: 40 }} />,
  },
  {
    id: 2,
    name: 'heart',
    balise: <FavoriteBorderIcon id="heart" style={{ fontSize: 40 }} />,
  },
  {
    id: 3,
    name: 'star',
    balise: <StarBorderIcon id="star" style={{ fontSize: 40 }} />,
  },
  {
    id: 4,
    name: 'key',
    balise: <VpnKeyIcon id="key" style={{ fontSize: 40 }} />,
  },
  {
    id: 5,
    name: 'car2',
    balise: <DriveEtaIcon id="car2" style={{ fontSize: 40 }} />,
  },
  {
    id: 6,
    name: 'tooth',
    balise: (
      <img id="tooth" width={30} height={30} src={iconTooth} alt="tooth" />
    ),
  },
  {
    id: 7,
    name: 'fire',
    balise: <img id="fire" width={30} height={30} src={iconFire} alt="fire" />,
  },
  {
    id: 8,
    name: 'glasses',
    balise: (
      <img
        id="glasses"
        width={30}
        height={30}
        src={iconLunette}
        alt="glasses"
      />
    ),
  },
];
function ListButtonLogo(props) {
  const ListButton = () => (
    <div>
      {listIcons.map((item) => (
        <span
          key={item.id}
          id={item.name}
          className="icon-edit"
          onClick={(e) => props.handlemyShape(e)}
        >
          {item.balise}
        </span>
      ))}
    </div>
  );

  return (
    <div className="col-md-6">
      <ListButton />
    </div>
  );
}
export default ListButtonLogo;
